import _ from "lodash";
import type { NextRouter } from "next/router";
import {
  capitalize,
  isNotNullAndNotUndefined,
  isNullOrUndefined,
} from "./formatting";
import { BillingMode } from "./generated/graphql";
import axios from "axios";
import ms from "ms";

export const isActualWithinExpectedRange = (
  expectedAmount,
  actualAmount
): boolean => {
  if (expectedAmount.type === "range") {
    return (
      actualAmount >= expectedAmount.minRange &&
      actualAmount <= expectedAmount.maxRange
    );
  }

  return actualAmount === expectedAmount.value;
};

export const expectedAmountIsZero = (expectedAmount: any): boolean => {
  if (expectedAmount.type === "range") {
    return expectedAmount.minRange === 0 && expectedAmount.maxRange === 0;
  }

  return expectedAmount.value === 0;
};

export const policyOrPlan = (isPlural: boolean, isCapitalized: boolean) => {
  const returnVal = isPlural ? "policies" : "policy";
  const capitalized = capitalize(returnVal);
  return isCapitalized ? capitalized : returnVal;
};

/** Used to assert that a given codepath is unreachable so Typescript
 * can better infer types.
 */
export const assertUnreachable = (): never => {
  throw new Error("Reached an unreachable codepath");
};

/** Used to assert that the value in a given codepath is unreachable so Typescript
 * can better infer types.
 */
export const assertUnreachableValue = (value: never): never => {
  throw new Error(`Reached an unreachable codepath with value ${value}`);
};

/** Ensures that the given value is not undefined or null - throws an error if it is. */
export function assertDefined<T>(
  value: T | undefined | null
): asserts value is T {
  if (isNullOrUndefined(value)) {
    throw new Error("Expected value to be defined");
  }
}

export const getArrayQueryParams = (
  router: NextRouter,
  keys: string[]
): { [key: typeof keys[number]]: string[] | undefined } => {
  const output = {};
  keys.forEach((key) => {
    const value = router.query[key];
    if (Array.isArray(value)) output[key] = value;
    if (typeof value === "string") {
      // TODO: Remove as part of
      // https://app.asana.com/0/1203364698217668/1205437942495252/f
      // Hack to maintain backwards compatibility with old query params
      try {
        output[key] = JSON.parse(value);
      } catch (e) {
        output[key] = value.split(",");
      }
    }
  });
  return output;
};

/** Gets query params in router and ensures they are string | undefined (not string array). */
export const getQueryParams = (
  router: NextRouter,
  keys: string[]
): { [key: typeof keys[number]]: string | undefined } => {
  const output = {};
  keys.forEach((key) => {
    const value = router.query[key];
    if (Array.isArray(value))
      throw new Error(
        `Query param ${key} cannot be an array. Invalid value: ${JSON.stringify(
          value
        )}`
      );
    output[key] = value;
  });
  return output;
};

/** Navigates to current path with given params. Merges given params with
 * current URL params, filters out null/undefined values, and updates URL params.
 * Returns a promise that resolves when the URL is updated.
 */
export const navigateToUrlState = (
  router: NextRouter,
  params: object,
  routerAction: "push" | "replace" = "push"
) => {
  const mergedParams = {
    ...(router.query as Record<string, string>),
    ...params,
  };
  const validParams = Object.entries(mergedParams).filter(([, v]) =>
    isNotNullAndNotUndefined(v)
  );
  const paramsStr = new URLSearchParams(validParams).toString();
  const url = `${router.pathname}${
    paramsStr.length > 0 ? `?${paramsStr}` : ""
  }`;
  if (routerAction === "push") {
    return router.push(url, undefined, { shallow: true });
  } else {
    return router.replace(url, undefined, { shallow: true });
  }
};

export const findOrFail = <T>(
  list: T[],
  predicate: (arg: T, index: number) => boolean
): T => {
  const result = list.find(predicate);
  if (result === undefined) {
    throw new Error("Could not find element");
  }
  return result;
};

/** A collection of functions and data representing a Map that can have objects as keys. Finds
 * value for given key by doing deep-comparison of keys. Not constant-time access.
 */
export type ObjectMap<KeyType, ValueType> = [KeyType, ValueType][];

export const getObjectMap = <KeyType, ValueType>(
  pairs: ObjectMap<KeyType, ValueType>,
  key: KeyType
): ValueType | undefined => {
  const pair = pairs.find(([k]) => _.isEqual(k, key));
  return pair === undefined ? undefined : pair[1];
};

/** Returns new object map representing the given key/value - preserves ordering of original insertion*/
export const putObjectMap = <KeyType, ValueType>(
  pairs: ObjectMap<KeyType, ValueType>,
  key: KeyType,
  value: ValueType
): ObjectMap<KeyType, ValueType> => {
  let newPairs = pairs;
  let insertIndex = 0;
  if (getObjectMap(pairs, key) !== undefined) {
    // If already an entry for this key, update it
    newPairs = pairs.filter(([k]) => !_.isEqual(k, key));
    insertIndex = pairs.findIndex(([k]) => _.isEqual(k, key));
  }
  return [
    ...newPairs.slice(0, insertIndex),
    [key, value],
    ...newPairs.slice(insertIndex),
  ];
};

/** Whether the given element is within the browser's viewport */
export const isInViewport = (element: HTMLElement) => {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

/** Simple hash function for a string */
export const hashString = (str: string) => {
  return [...str].reduce((hash, char) => {
    const charNum = char.charCodeAt(0);
    const bitShifted = (hash << 5) - hash + charNum;
    return bitShifted & bitShifted;
  }, 0);
};

export const isEmptyQuery = (query: object) =>
  !Object.values(query).filter(isNotNullAndNotUndefined).length;

/** Returns a boolean whether a given string value is a valid enum value. */
export function isValidEnumValue<T extends string, TEnumValue extends string>(
  enumVariable: { [key in T]: TEnumValue },
  value: any
): value is TEnumValue {
  const enumValues = Object.values(enumVariable);
  return enumValues.includes(value);
}

/** Retrieves and parses JSON data from provided URL */
export const fetchObjectFromAccessUrl = async (accessUrl: string) => {
  const blob = await (await fetch(accessUrl)).blob();
  return JSON.parse(await blob.text());
};

/** Retrieves and generates File object from provided URL */
export const fetchFileFromAccessUrl = async (
  fileName: string,
  accessUrl: string
): Promise<File> => {
  const response = await axios.get(accessUrl, {
    responseType: "blob",
    timeout: ms("2min"),
  });

  return new File([response.data], fileName);
};

export function getNodes<T>(data: { edges: { node: T }[] } | undefined) {
  return data?.edges.map(({ node }) => node);
}

export function safeJSONParse(raw: any, fallback: any) {
  try {
    return JSON.parse(raw);
  } catch (e) {
    return fallback;
  }
}

export const downloadFileAsAttachment = (downloadUrl: string) => {
  // Create an anchor element to trigger the download
  const downloadLink = document.createElement("a");
  downloadLink.href = downloadUrl;
  downloadLink.download = "true";
  document.body.appendChild(downloadLink);

  // Trigger the click event to initiate the download
  downloadLink.click();

  // Clean up the temporary anchor element
  document.body.removeChild(downloadLink);
};

export const isDirectBill = (billingMode: BillingMode) => {
  return billingMode === BillingMode.DIRECT_BILL;
};
export const isAgencyBill = (billingMode: BillingMode) => {
  return billingMode === BillingMode.AGENCY_BILL;
};

export function encodeQueryParams<T extends object>(
  filterState: T
): Record<keyof any, unknown> {
  const out: {
    [Key in keyof T]?: string | string[];
  } = {};
  Object.entries(filterState).forEach((entry) => {
    const [key, val] = entry;
    if (!key) return;
    else if (Array.isArray(val)) out[key] = JSON.stringify(val);
    else if (typeof val === "object" && val !== null && !(val instanceof Date))
      out[key] = JSON.stringify(val);
    else if (typeof val === "boolean") out[key] = val.toString();
    else if (val instanceof Date) out[key] = val.toUTCString();
    else out[key] = val;
  });

  return out;
}
