"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRowMatchText = exports.getRowMatches = void 0;
const utils_1 = require("../../common/utils");
const graphql_types_1 = require("@comulate/graphql-types");
const constants_1 = require("../constants");
const constants_2 = require("./constants");
const utils_2 = require("./utils");
const _ = __importStar(require("lodash"));
const getRowMatches = (boundaryConfigs, rows, baseRequiredFields, errorThreshold, lineWrapEnabledFields = [], autofillSteps, overrideMatchConfig, flags) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b;
    const childFields = boundaryConfigs
        .map(([, config]) => config.childFields)
        .flat();
    const childFieldTypes = boundaryConfigs.map(([, config]) => config.type);
    const allMatches = [];
    const { rowMatchValidators } = overrideMatchConfig || {};
    const { forceCascadeEnabledFields } = flags || {};
    for (let rowInd = 0; rowInd < rows.length; rowInd++) {
        const row = rows[rowInd];
        // Yield event loop every 1000 rows
        if (rowInd % 1000 === 0)
            yield (0, utils_1.yieldEventLoop)();
        const rowMatches = [];
        let fieldInd = 0; // Index of field currently being parsed
        for (let j = 0; j < row.length; j++) {
            const col = row[j];
            // Skip to next field if current cell is beyond the current field's start bound
            while (fieldInd < boundaryConfigs.length - 1 &&
                (0, utils_2.getStartPosition)(boundaryConfigs[fieldInd][1].startBound) +
                    errorThreshold <
                    (0, utils_2.getAlignedPosition)(((_a = boundaryConfigs[fieldInd][1]) === null || _a === void 0 ? void 0 : _a.alignment) ===
                        graphql_types_1.AdemFieldAlignment.CENTER
                        ? graphql_types_1.AdemFieldAlignment.LEFT
                        : boundaryConfigs[fieldInd][1].alignment, col)) {
                fieldInd += 1;
            }
            const field = boundaryConfigs[fieldInd][0];
            const boundaryConfig = boundaryConfigs[fieldInd][1];
            const withinFieldStartBound = boundaryConfig.alignment === graphql_types_1.AdemFieldAlignment.CENTER
                ? (0, utils_2.getAlignedPosition)(graphql_types_1.AdemFieldAlignment.LEFT, col) - errorThreshold <=
                    (0, utils_2.getStartPosition)(boundaryConfig.startBound) &&
                    (0, utils_2.getAlignedPosition)(graphql_types_1.AdemFieldAlignment.RIGHT, col) +
                        errorThreshold >=
                        (0, utils_2.getStartPosition)(boundaryConfig.startBound)
                : blockHasValidEnd(col, boundaryConfig, errorThreshold) &&
                    blockHasValidStart(col, boundaryConfig);
            // Extract rest of field once we've determined the first cell block
            // given match with start bound
            if (withinFieldStartBound) {
                const { nextJ, blocks } = parseField(row, j, boundaryConfig, flags);
                const { filter, type: fieldType } = boundaryConfig;
                if ((0, utils_2.isValidMatch)(row, blocks, field, fieldType, flags || {}, filter)) {
                    // Handle multiple matches for a single field - since matches move left to right,
                    // if there is a valid match following an existing valid match the first was likely
                    // a premature match
                    if (((_b = rowMatches[rowMatches.length - 1]) === null || _b === void 0 ? void 0 : _b[0]) === field) {
                        rowMatches[rowMatches.length - 1] = [field, blocks];
                    }
                    else {
                        rowMatches.push([field, blocks]);
                    }
                    j = nextJ;
                }
            }
            if (fieldInd > boundaryConfigs.length - 1) {
                break;
            }
        }
        if (rowMatches.length) {
            allMatches.push({
                matches: rowMatches,
                rowIndex: row[0].rowIndex,
                absoluteRowIndex: row[0].absoluteRowIndex,
                nextRowIndex: row[0].rowIndex + 1,
                rowPosition: row[0].boundingBox.y,
                page: row[0].page,
                childFields,
                childFieldTypes,
                rowContainsUnmatchedBlocks: row.some((cell) => !rowMatches.some(([, blocks]) => blocks.includes(cell))),
            });
        }
    }
    autofillSteps.push({
        name: "GET_ALL_MATCHES",
        rowMatches: allMatches,
        fieldConfigs: boundaryConfigs,
        rows,
    });
    if (lineWrapEnabledFields.length) {
        // In-place merges row matches to handle text overflow
        mergeTextOverflow(boundaryConfigs.length, allMatches, lineWrapEnabledFields, flags === null || flags === void 0 ? void 0 : flags.forceLineWrapNumLines);
    }
    const validatedRowMatches = filterValidRowMatches(boundaryConfigs, allMatches, rows, baseRequiredFields, forceCascadeEnabledFields || [], rowMatchValidators, flags);
    return validatedRowMatches.map((row) => extractRowMatches(row, boundaryConfigs));
});
exports.getRowMatches = getRowMatches;
const blockHasValidEnd = (block, boundaryConfig, errorThreshold) => Math.abs((0, utils_2.getAlignedPosition)(boundaryConfig.alignment, block) -
    (0, utils_2.getStartPosition)(boundaryConfig.startBound)) < errorThreshold;
const blockHasValidStart = (block, boundaryConfig) => {
    if (boundaryConfig.alignment === graphql_types_1.AdemFieldAlignment.RIGHT) {
        return ((0, utils_2.getAlignedPosition)(graphql_types_1.AdemFieldAlignment.LEFT, block) <
            (0, utils_2.getStartPosition)(boundaryConfig.startBound));
    }
    if (boundaryConfig.alignment === graphql_types_1.AdemFieldAlignment.LEFT) {
        return ((0, utils_2.getAlignedPosition)(graphql_types_1.AdemFieldAlignment.RIGHT, block) >
            (0, utils_2.getStartPosition)(boundaryConfig.startBound));
    }
    throw new Error("Alignment should be either LEFT or RIGHT");
};
const extractRowMatches = (row, boundaryConfigs) => {
    const updatedMatches = row.matches
        .map(([matchedField, matchedBlocks]) => {
        const [configField, config] = (0, utils_1.findOrFail)(boundaryConfigs, ([header]) => header === matchedField);
        if (matchedField === configField && config.extractor) {
            return Object.entries(config.extractor(matchedBlocks))
                .map(([header, blocks]) => {
                if (blocks.length)
                    return [header, blocks];
            })
                .filter(utils_1.isNotNullAndNotUndefined);
        }
        return [[matchedField, matchedBlocks]];
    })
        .flat();
    return Object.assign(Object.assign({}, row), { matches: updatedMatches });
};
const containsOverflow = (numFields, prevRowMatch, rowMatch, lineWrapEnabledFields, forceLineWrapNumLines) => {
    const { matches } = rowMatch;
    const avgBlockHeight = _.mean(matches
        .map(([, blocks]) => blocks.map((block) => block.boundingBox.height))
        .flat());
    return (matches.length &&
        matches.length <= numFields &&
        (matches.every(([header]) => lineWrapEnabledFields.includes(header)) ||
            matches.some(([header]) => forceLineWrapNumLines === null || forceLineWrapNumLines === void 0 ? void 0 : forceLineWrapNumLines[header])) &&
        rowMatch.rowPosition - prevRowMatch.rowPosition <
            2 * Math.max(avgBlockHeight, 0.01));
};
/**
 * Given a list of row matches with duplicate fields, merge the matches so
 * there aren't any duplicate fields like follows:
 * [["Line", [blockA, blockB]], ["Line", [blockC, blockD]]] =>
 *     [["Line", [blockA, blockB, blockC, blockD]]]
 *
 * @param matches
 * @returns
 */
const mergeMatches = (matches, lineWrapEnabledFields) => {
    const grouped = _.groupBy(matches, ([header]) => header);
    return Object.entries(grouped).map(([header, matches]) => [
        header,
        lineWrapEnabledFields.includes(header)
            ? matches.map(([, blocks]) => blocks).flat()
            : matches[0][1],
    ]);
};
/**
 * Merges cells where the text overflowed into the next cell, determined
 * by the presence of text *only* in the variable length text fields in the
 * following row.
 *
 * @param numFields
 * @param rowMatches
 * @returns
 */
const mergeTextOverflow = (numFields, rowMatches, lineWrapEnabledFields, forceLineWrapNumLines) => {
    var _a;
    const maxNumLineWrapLines = _.maxBy(lineWrapEnabledFields.map((field) => { var _a; return (_a = forceLineWrapNumLines === null || forceLineWrapNumLines === void 0 ? void 0 : forceLineWrapNumLines[field]) !== null && _a !== void 0 ? _a : 0; })) || Infinity;
    let i = 0;
    while (i < rowMatches.length) {
        const parentRow = rowMatches[i];
        if (!parentRow.matches.some(([header]) => lineWrapEnabledFields.includes(header))) {
            i++;
            continue;
        }
        let j = i + 1;
        while (j < rowMatches.length &&
            // Only merge if next row is immediately below current row
            rowMatches[j].rowIndex - rowMatches[i].rowIndex <= j - i &&
            j - i < maxNumLineWrapLines &&
            containsOverflow(numFields, rowMatches[j - 1], rowMatches[j], lineWrapEnabledFields, forceLineWrapNumLines)) {
            const [lineWrappedRowMatchFields, remainingRowMatchFields] = _.partition(rowMatches[j].matches, ([header]) => lineWrapEnabledFields.includes(header));
            parentRow.matches.push(...lineWrappedRowMatchFields);
            rowMatches[j].matches = remainingRowMatchFields;
            j++;
        }
        // If there are consecutive row matches, merge them
        if (j - i > 1) {
            parentRow.matches = mergeMatches(parentRow.matches, lineWrapEnabledFields);
            // Set nextRowIndex to end of line-wrapped row set to handle consecutive
            // row requirement for cascading
            parentRow.nextRowIndex =
                ((_a = rowMatches[j - 1]) === null || _a === void 0 ? void 0 : _a.rowIndex) + 1 || rowMatches.length;
        }
        i = j;
    }
};
/**
 * Gets minimum number of fields necessary for considering row a valid partial
 * row - expect all numeric fields to be present, as well as at least one non-numeric
 * field as accounting entity.
 *
 * @param fields
 * @returns
 */
const getMinRequiredFields = (boundaryConfigs) => {
    const ademFields = boundaryConfigs.filter(([header]) => constants_1.ADEM_STANDARD_HEADERS.includes(header));
    return Math.min(ademFields.length, boundaryConfigs.filter(([, { type }]) => type === graphql_types_1.AdemFieldType.NUMBER)
        .length + 1);
};
const filterRequiredFields = (baseRequiredFields, fields, requireOnlyAdemFields) => fields.filter((header) => 
// All fields default to required unless explicitly overridden (so shadow
// fields are all by default required)
requireOnlyAdemFields
    ? baseRequiredFields.includes(header)
    : (0, utils_2.isShadowField)(header) || baseRequiredFields.includes(header));
const containsRequiredFields = (baseRequiredFields, boundaryConfigs, rowMatch, requireOnlyAdemFields) => {
    const requiredFields = filterRequiredFields(baseRequiredFields, requireOnlyAdemFields
        ? boundaryConfigs.map(([header]) => header)
        : boundaryConfigs.map(([, config]) => config.childFields).flat(), requireOnlyAdemFields);
    const matchedFields = rowMatch.matches
        .filter(([, blocks]) => blocks.length > 0)
        .map(([header]) => header);
    return (rowMatch.matches.length >= boundaryConfigs.length ||
        requiredFields.every((header) => matchedFields.includes(header)));
};
/**
 * Filters row matches for rows that have at least the minimum number of required fields -
 * for each row, this is determined to be the total number of labeled fields *unless*
 * the row is only missing consecutive fields at the start of the row, in which case the
 * row is still considered to be valid as we suspect the row has fields requiring cascading.
 *
 * For sparse configs, additionally ensures the following:
 *   1. There are no matches between consecutive fields
 *   2. If the the original labeled field marks the start/end of a line, the matched
 *      field also has no content before/after it
 *
 * @param boundaryConfigs
 * @param rowMatches
 * @returns
 */
const filterValidRowMatches = (boundaryConfigs, rowMatches, rows, baseRequiredFields, forceCascadeEnabledFields, rowMatchValidators, flags) => {
    const requiredFields = baseRequiredFields.filter((field) => !forceCascadeEnabledFields.includes(field));
    const minRequiredFields = getMinRequiredFields(boundaryConfigs);
    const rowsByAbsoluteIndex = _.keyBy(rows, (row) => row[0].absoluteRowIndex);
    const validRowMatches = [];
    for (let i = 0; i < rowMatches.length; i++) {
        const rowMatch = rowMatches[i];
        let isValidMatch = false;
        // By default, row match is valid if it contains all required fields
        if (containsRequiredFields(requiredFields, boundaryConfigs, rowMatch)) {
            isValidMatch = true;
        }
        // Consider match valid if all fields are consecutively at end of row
        if (!isValidMatch) {
            const matchedFields = _.uniq([
                ...rowMatch.matches.map(([field]) => field),
                ...forceCascadeEnabledFields,
            ]);
            isValidMatch =
                (0, utils_2.containsEndOfRow)(boundaryConfigs.map(([, { type }]) => type), requiredFields, boundaryConfigs.map(([header]) => header), rowMatch.matches.map(([header]) => header)) &&
                    matchedFields.filter((header) => constants_1.ADEM_STANDARD_HEADERS.includes(header))
                        .length >= minRequiredFields;
        }
        // Add additional validation on sparse row signatures
        if (isValidMatch &&
            !(flags === null || flags === void 0 ? void 0 : flags.disableShadowFields) &&
            boundaryConfigs.length <= constants_2.SHADOW_FIELD_THRESHOLD) {
            boundaryConfigs.forEach((boundaryConfig, j) => {
                const header = boundaryConfig[0];
                const fieldMatchConfig = boundaryConfig[1];
                const matches = Object.fromEntries(rowMatch.matches);
                const matchedBlocks = matches[header];
                // Add additional validation on row match unless filter manually specified
                if (!(0, utils_2.isShadowField)(header) &&
                    !fieldMatchConfig.skipSparseFieldValidation &&
                    (0, utils_1.isNotNullAndNotUndefined)(matchedBlocks)) {
                    const containingRow = rowsByAbsoluteIndex[rowMatch.absoluteRowIndex] || [];
                    const matchIndices = (matchedBlocks.map((block) => containingRow.findIndex(({ id }) => id === block.id)) || []).filter((ind) => ind !== -1);
                    const nextFieldConfig = boundaryConfigs[j + 1];
                    if (nextFieldConfig && matches[nextFieldConfig[0]]) {
                        const nextMatchIndices = matches[nextFieldConfig[0]]
                            .map((block) => containingRow.findIndex(({ id }) => id === block.id))
                            .filter((ind) => ind !== -1);
                        const blocksBetween = containingRow.slice(Math.max(...matchIndices) + 1, Math.min(...nextMatchIndices));
                        // Ensure no content between matches
                        isValidMatch = blocksBetween.every((block) => !blockContainsValidFixedContent(block, !!(flags === null || flags === void 0 ? void 0 : flags.ignoreFillerBlocks)));
                    }
                    if (fieldMatchConfig.isEndField) {
                        // Ensure no content after match
                        isValidMatch =
                            isValidMatch && matchIndices.includes(containingRow.length - 1);
                    }
                    if (fieldMatchConfig.isStartField) {
                        // Ensure no content before match
                        isValidMatch = isValidMatch && matchIndices.includes(0);
                    }
                }
            });
        }
        // Consider match valid if explicitly overridden to be valid
        if (rowMatchValidators === null || rowMatchValidators === void 0 ? void 0 : rowMatchValidators.some(({ validator }) => validator(rowMatch))) {
            isValidMatch = true;
        }
        if (isValidMatch) {
            validRowMatches.push(rowMatch);
        }
    }
    // Only match rows whose text only appears once to strictly guard against misc
    // non-uniform content interfering with matches if requireStrictlyUniqueRowMatches
    if (boundaryConfigs.some(([, config]) => config.requireStrictlyUniqueRowMatches)) {
        const textCounts = _.countBy(validRowMatches, (row) => (0, exports.getRowMatchText)(row, ""));
        return validRowMatches.filter((row) => textCounts[(0, exports.getRowMatchText)(row, "")] === 1);
    }
    // Only allow first row of text to match if requireUniqueRowMatches
    if (boundaryConfigs.some(([, config]) => config.requireUniqueRowMatches)) {
        return _.uniqBy(validRowMatches, (row) => (0, exports.getRowMatchText)(row, ""));
    }
    return validRowMatches;
};
const blockContainsValidFixedContent = (block, ignoreFillerBlocks) => !ignoreFillerBlocks || /[a-zA-Z0-9$]/.test(block.text);
const getRowMatchText = (row, delimiter = " ") => row.matches
    .map(([, blocks]) => blocks.map((block) => block.text))
    .flat()
    .join(delimiter);
exports.getRowMatchText = getRowMatchText;
/**
 * Extracts provided field up to end boundaries in both left + right directions
 * depending on what is provided in config. This logic only checks if field is
 * within provided boundaries, not whether the field is actually a valid match.
 *
 * @param row
 * @param startJ
 * @param alignment
 * @param endBound
 * @returns
 */
const parseField = (row, startJ, { endBound, meanBlockGap, type: fieldType }, flags) => {
    const minJ = endBound.left
        ? getEndBoundaryInd(fieldType, row, startJ, "left", endBound.left, meanBlockGap, flags)
        : startJ;
    const maxJ = endBound.right
        ? getEndBoundaryInd(fieldType, row, startJ, "right", endBound.right, meanBlockGap, flags)
        : startJ + 1;
    return {
        nextJ: Math.max(maxJ - 1, startJ),
        blocks: row.slice(minJ, maxJ),
    };
};
/**
 * Extracts index of end boundary of field starting at startJ and moving in
 * provided direction until endBound is reached.
 *
 * @param row
 * @param startJ
 * @param direction
 * @param endBound
 * @returns
 */
const getEndBoundaryInd = (fieldType, row, startJ, direction, endBound, meanBlockGap, flags) => {
    let endJ = startJ;
    const ignoreFillerBlocks = !!(flags === null || flags === void 0 ? void 0 : flags.ignoreFillerBlocks);
    const parseStrictFlags = {
        parseDateStrict: fieldType !== graphql_types_1.AdemFieldType.DATE,
        parseNumberStrict: fieldType !== graphql_types_1.AdemFieldType.NUMBER,
    };
    if (direction === "right") {
        if ((0, utils_2.isPositionBoundaryConfig)(endBound)) {
            let j = startJ;
            while (j < row.length &&
                (0, utils_2.getAlignedPosition)(graphql_types_1.AdemFieldAlignment.RIGHT, row[j]) <
                    endBound.positionX + constants_2.DEFAULT_OFFSET_ERROR_THRESHOLD &&
                (0, utils_2.getAlignedPosition)(graphql_types_1.AdemFieldAlignment.LEFT, row[j]) <
                    endBound.positionX - constants_2.DEFAULT_OFFSET_ERROR_THRESHOLD &&
                (j <= startJ ||
                    nextBlockWithinThreshold(row[j - 1], row[j], meanBlockGap && meanBlockGap * 2))) {
                j += 1;
            }
            endJ = j;
        }
        else if ((0, utils_2.isCellMatchBoundaryConfig)(endBound)) {
            let j = startJ;
            while (j < row.length &&
                (j === startJ ||
                    (0, utils_2.getValidBlockTypes)([row[j]], parseStrictFlags).some((blockType) => blockType !== endBound.cellMatchType) ||
                    (endBound.cellPositionX &&
                        cellMatchBlockCloserToCurrentMatch(row[j - 1], row[j], endBound.cellPositionX))) &&
                (endBound.cellPositionX
                    ? (0, utils_2.getAlignedPosition)(graphql_types_1.AdemFieldAlignment.RIGHT, row[j]) <
                        endBound.cellPositionX + constants_2.DEFAULT_OFFSET_ERROR_THRESHOLD
                    : true) &&
                (j <= startJ ||
                    nextBlockWithinThreshold(row[j - 1], row[j], meanBlockGap && meanBlockGap * 4))) {
                j += 1;
            }
            endJ = j;
        }
        else if ((0, utils_2.isFixedBlocksBoundaryConfig)(endBound)) {
            let j = startJ;
            let numMatchedBlocks = 0;
            while (j < row.length &&
                numMatchedBlocks < endBound.numBlocks &&
                (j <= startJ ||
                    nextBlockWithinThreshold(row[j - 1], row[j], meanBlockGap && meanBlockGap * 2))) {
                if (blockContainsValidFixedContent(row[j], ignoreFillerBlocks)) {
                    numMatchedBlocks += 1;
                }
                j += 1;
            }
            endJ = j;
        }
        return endJ;
    }
    else {
        if ((0, utils_2.isPositionBoundaryConfig)(endBound)) {
            let j = startJ;
            while (j >= 0 &&
                (0, utils_2.getAlignedPosition)(graphql_types_1.AdemFieldAlignment.LEFT, row[j]) >
                    endBound.positionX - constants_2.DEFAULT_OFFSET_ERROR_THRESHOLD &&
                (0, utils_2.getAlignedPosition)(graphql_types_1.AdemFieldAlignment.RIGHT, row[j]) >
                    endBound.positionX + constants_2.DEFAULT_OFFSET_ERROR_THRESHOLD &&
                (j >= startJ ||
                    nextBlockWithinThreshold(row[j], row[j + 1], meanBlockGap && meanBlockGap * 2))) {
                j -= 1;
            }
            endJ = j;
        }
        else if ((0, utils_2.isCellMatchBoundaryConfig)(endBound)) {
            let j = startJ;
            while (j >= 0 &&
                (j === startJ ||
                    (0, utils_2.getValidBlockTypes)([row[j]], parseStrictFlags).some((blockType) => blockType !== endBound.cellMatchType)) &&
                (endBound.cellPositionX
                    ? (0, utils_2.getAlignedPosition)(graphql_types_1.AdemFieldAlignment.LEFT, row[j]) >
                        endBound.cellPositionX - constants_2.DEFAULT_OFFSET_ERROR_THRESHOLD
                    : true) &&
                (j >= startJ ||
                    nextBlockWithinThreshold(row[j], row[j + 1], meanBlockGap && meanBlockGap * 4))) {
                j -= 1;
            }
            endJ = j;
        }
        else if ((0, utils_2.isFixedBlocksBoundaryConfig)(endBound)) {
            let j = startJ;
            let numMatchedBlocks = 0;
            while (j >= 0 &&
                numMatchedBlocks < endBound.numBlocks &&
                (j >= startJ ||
                    nextBlockWithinThreshold(row[j], row[j + 1], meanBlockGap && meanBlockGap * 2))) {
                if (blockContainsValidFixedContent(row[j], ignoreFillerBlocks)) {
                    numMatchedBlocks += 1;
                }
                j -= 1;
            }
            endJ = j;
        }
        return endJ + 1;
    }
};
const nextBlockWithinThreshold = (block, nextBlock, allowedOffset) => (0, utils_2.getAlignedPosition)(graphql_types_1.AdemFieldAlignment.LEFT, nextBlock) -
    (0, utils_2.getAlignedPosition)(graphql_types_1.AdemFieldAlignment.RIGHT, block) <
    Math.max(allowedOffset || 0, constants_2.DEFAULT_OFFSET_ERROR_THRESHOLD * 2);
const cellMatchBlockCloserToCurrentMatch = (block, nextBlock, cellPositionX) => nextBlockWithinThreshold(block, nextBlock) &&
    Math.abs(cellPositionX - (0, utils_2.getAlignedPosition)(graphql_types_1.AdemFieldAlignment.RIGHT, nextBlock)) >
        5 * constants_2.DEFAULT_OFFSET_ERROR_THRESHOLD;
