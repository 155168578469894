import {
  CashReceivedBatch,
  CashReceivedBatchFileType,
  CashReceivedPayment,
} from "@comulate/graphql-types";
import { parse } from "tldts";
import { GLOBAL_NAV } from "../components/Appbar/navigationConstants";
import { Url } from "next/dist/shared/lib/router/router";

export type DepositUrlPayment = Pick<CashReceivedPayment, "id"> & {
  cashReceivedBatch?: Pick<CashReceivedBatch, "id" | "fileType"> | null;
};

export const getDepositUrl = (cashReceivedPayment: DepositUrlPayment) => {
  const { id, cashReceivedBatch } = cashReceivedPayment;

  return !cashReceivedBatch
    ? null
    : cashReceivedBatch.fileType === CashReceivedBatchFileType.PDF
    ? `/deposits?batchId=${cashReceivedBatch?.id}&cashReceivedPaymentId=${id}&view=pdf`
    : `/deposits?batchId=${cashReceivedBatch?.id}&cashReceivedPaymentId=${id}&view=tabular`;
};

abstract class BaseUrl {
  abstract getPathname(): string;

  abstract getQueryParameters(): Record<string, any>;

  getHref() {
    return (
      this.getPathname() +
      "?" +
      Object.entries(this.getQueryParameters())
        .filter(([, value]) => value !== undefined)
        .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
        .join("&")
    );
  }

  getUrl(): Url {
    return {
      pathname: this.getPathname(),
      query: this.getQueryParameters(),
    };
  }
}

type LedgerUrlInput = { periodId?: string; statementId?: string };

export class LedgerUrl extends BaseUrl {
  private periodId?: string;
  private statementId?: string;

  constructor({ periodId, statementId }: LedgerUrlInput) {
    super();

    this.periodId = periodId;
    this.statementId = statementId;
  }

  getPathname = () => GLOBAL_NAV.LEDGER.href;

  getQueryParameters = () => ({
    periodId: this.periodId,
    statementId: this.statementId,
  });

  static generate = (input: LedgerUrlInput = {}): Url => {
    const ledgerUrl = new LedgerUrl(input);

    return ledgerUrl.getUrl();
  };

  static generateHref = (input: LedgerUrlInput = {}): string => {
    const ledgerUrl = new LedgerUrl(input);

    return ledgerUrl.getHref();
  };
}

type PayablesUrlInput =
  | { type: "statement"; statementId: string }
  | { type: "invoice"; invoiceStatementId: string }
  | { type: "root" };

export function getPayablesUrl(input: PayablesUrlInput): string {
  const payablesRoot = GLOBAL_NAV.PAYABLES.href;

  switch (input.type) {
    case "statement":
      return input.statementId
        ? `${payablesRoot}/statement/${input.statementId}`
        : payablesRoot;
    case "invoice":
      return `${payablesRoot}/invoices/${input.invoiceStatementId}`;
    case "root":
      return payablesRoot;
  }
}

/**
 * Get the root domain of a given URL
 * @param url - a URL string or URL object
 * @returns the root domain of the given URL, e.g. "example.com" for "https://www.example.com"
 */
export function getRootDomain(url: string | URL): string {
  const cleanedUrlString = url.toString().trim();
  const domain = parse(cleanedUrlString).domain;
  return domain ?? "";
}
