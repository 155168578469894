export default function Loader({ style }: { style?: React.CSSProperties }) {
  return (
    <div className="loader">
      <div style={style} />
      <div style={style} />
      <div style={style} />
      <div style={style} />
    </div>
  );
}
