import { useMemo } from "react";
import { assertDefined, assertUnreachableValue } from "../../src/utils";
import { formatCarrierStatementName, pluralize } from "../../src/formatting";
import {
  BillingMode,
  PayableEntryStatusesUpdateNotificationPayload,
  PayableEntryStatus,
  PayableEntryStatusesUpdateNotificationReason,
  ExternalStatementDataType,
} from "../../src/generated/graphql";
import { BaseNotification } from "./BaseNotification";
import { getPayablesUrl } from "../../src/urlUtils";

type CarrierStatement = NonNullable<
  PayableEntryStatusesUpdateNotificationPayload["statement"]
>;
export const PayableEntriesPayableEntryStatusUpdateNotification = ({
  payload,
  createdAt,
}: {
  payload: PayableEntryStatusesUpdateNotificationPayload;
  createdAt: string;
}) => {
  const { statement } = payload;

  assertDefined(statement);

  const numReconciledEntries = useMemo(
    () =>
      payload.payableEntries.filter(
        ({ billingMode, payableEntryStatus }) =>
          payableEntryStatus === payload.payableEntryStatus &&
          billingMode === BillingMode.AGENCY_BILL
      ).length,
    [payload.payableEntries, payload.payableEntryStatus]
  );
  const link = generateLink(statement, payload);

  const displayReason = useMemo(() => {
    const payloadReason = payload.reason;

    switch (payloadReason) {
      case PayableEntryStatusesUpdateNotificationReason.RECV_BAL_CHANGE:
        return "in response to cash being applied";
      case PayableEntryStatusesUpdateNotificationReason.TRANSACTION_PAYABLE_BAL_CHANGE:
      case PayableEntryStatusesUpdateNotificationReason.TRANSACTION_PAYABLE_STATUS_CHANGE:
        return "in response to transaction data changes";
      default:
        assertUnreachableValue(payloadReason);
    }
  }, [payload.reason]);

  return (
    <BaseNotification href={link} user={null} createdAt={createdAt}>
      {({ userLabel }) => (
        <>
          <span className="font-medium text-zinc-900">{userLabel}</span>{" "}
          {" marked "}
          {pluralize(numReconciledEntries, "transaction")} {" from "}
          <span className="font-medium text-green-900">
            {/* This doesn't totally make sense in the context of an invoice statement
            but it's not _totally_ wrong */}
            {formatCarrierStatementName(statement)}
          </span>{" "}
          as{" "}
          <span className="font-medium text-green-900">
            {payableStatusToAdjective(payload.payableEntryStatus)}
          </span>{" "}
          {displayReason}
        </>
      )}
    </BaseNotification>
  );
};

function payableStatusToAdjective(status: PayableEntryStatus) {
  switch (status) {
    case PayableEntryStatus.PAY:
      return "Paying";
    case PayableEntryStatus.NOT_PAYING:
      return "Not paying";
    case PayableEntryStatus.PARTIAL:
      return "Partially paying";
    case PayableEntryStatus.POSTED:
    case PayableEntryStatus.POST_FAILED:
      return "Paid";
    case PayableEntryStatus.REVIEW:
      return "Review";
    default:
      assertUnreachableValue(status);
  }
}

function generateLink(
  statement: CarrierStatement,
  payload: PayableEntryStatusesUpdateNotificationPayload
) {
  if (
    statement.externalMetadata?.type ===
    ExternalStatementDataType.PAYABLE_INVOICE
  ) {
    return getPayablesUrl({
      type: "invoice",
      invoiceStatementId: statement.id,
    });
  }
  return `${getPayablesUrl({
    type: "statement",
    statementId: statement.id,
  })}?client=${encodeURI(payload.client)}&status=${encodeURI(
    payload.payableEntryStatus
  )}`;
}
