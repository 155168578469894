"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_LINE_WRAP_ENABLED_FIELDS = exports.DECIMAL_SPLICE_ENABLED_FIELDS = exports.SHADOW_FIELD_TOKEN = exports.SHADOW_FIELD_THRESHOLD = exports.DEFAULT_ALLOWED_SECTION_OFFSET_MULTIPLIER = exports.ALIGNMENT_OFFSET_THRESHOLD = exports.LINE_OFFSET_ERROR_THRESHOLD = exports.DEFAULT_OFFSET_ERROR_THRESHOLD = exports.MIN_OFFSET_ERROR_THRESHOLD = void 0;
const graphql_types_1 = require("@comulate/graphql-types");
const constants_1 = require("../constants");
exports.MIN_OFFSET_ERROR_THRESHOLD = 0.008;
exports.DEFAULT_OFFSET_ERROR_THRESHOLD = 0.01;
exports.LINE_OFFSET_ERROR_THRESHOLD = 0.003;
exports.ALIGNMENT_OFFSET_THRESHOLD = 0.001;
exports.DEFAULT_ALLOWED_SECTION_OFFSET_MULTIPLIER = 0.5;
// Number of labeled fields up to which shadow fields will be generated
exports.SHADOW_FIELD_THRESHOLD = 3;
// Will be appended to field name to indicate field is shadow field
exports.SHADOW_FIELD_TOKEN = " ** Shadow ** ";
exports.DECIMAL_SPLICE_ENABLED_FIELDS = [
    graphql_types_1.AdemField.PREMIUM,
    graphql_types_1.AdemField.COMMISSION,
].map((field) => constants_1.ADEM_FIELDS_METADATA[field].header);
exports.DEFAULT_LINE_WRAP_ENABLED_FIELDS = [
    graphql_types_1.AdemField.CLIENT,
    graphql_types_1.AdemField.LINE,
].map((field) => constants_1.ADEM_FIELDS_METADATA[field].header);
