"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BankStatementMapper = void 0;
const baseMapper_1 = require("./baseMapper");
const utils_1 = require("../common/utils");
const graphql_types_1 = require("@comulate/graphql-types");
const jsonParser_1 = require("./jsonParser");
const types_1 = require("./types");
class BankStatementMapper extends baseMapper_1.BaseMapper {
    getValueByField(rowIndex, field) {
        switch (field) {
            case graphql_types_1.DepositField.ENTITY_NAME:
                return this.getEntityName(rowIndex);
            case graphql_types_1.DepositField.DESCRIPTION:
                return this.getDescription(rowIndex);
            case graphql_types_1.DepositField.AMOUNT:
                return this.getAmount(rowIndex);
            case graphql_types_1.DepositField.PAYMENT_DATE:
                return this.getPaymentDate(rowIndex);
            case graphql_types_1.DepositField.PAYMENT_REFERENCE_KEY:
                return this.getPaymentReferenceKey(rowIndex);
            case graphql_types_1.DepositField.ORIGINATING_COMPANY_NAME:
                return this.getOriginatingCompanyName(rowIndex);
            case graphql_types_1.DepositField.ORIGINATING_COMPANY_ID:
                return this.getOriginatingCompanyId(rowIndex);
            case graphql_types_1.DepositField.PAYMENT_METHOD:
                return this.getPaymentMethod(rowIndex);
            case graphql_types_1.DepositField.SHOULD_IGNORE_FOR_PROCESSING:
                return this.getShouldIgnoreForProcessing(rowIndex);
            case graphql_types_1.DepositField.EXTERNAL_ID:
                return this.getExternalId(rowIndex);
            default:
                return (0, utils_1.assertUnreachableValue)(field);
        }
    }
    getEntityName(rowIndex) {
        return this.getProcessedValue(rowIndex, graphql_types_1.DepositField.ENTITY_NAME);
    }
    getDescription(rowIndex) {
        return this.getProcessedValue(rowIndex, graphql_types_1.DepositField.DESCRIPTION);
    }
    getAmount(rowIndex) {
        return this.getProcessedValue(rowIndex, graphql_types_1.DepositField.AMOUNT, this.decimalParseMode);
    }
    getPaymentDate(rowIndex) {
        return this.getDate(rowIndex, graphql_types_1.DepositField.PAYMENT_DATE);
    }
    getPaymentReferenceKey(rowIndex) {
        return this.getProcessedValue(rowIndex, graphql_types_1.DepositField.PAYMENT_REFERENCE_KEY);
    }
    getOriginatingCompanyName(rowIndex) {
        return this.getProcessedValue(rowIndex, graphql_types_1.DepositField.ORIGINATING_COMPANY_NAME);
    }
    getOriginatingCompanyId(rowIndex) {
        return this.getProcessedValue(rowIndex, graphql_types_1.DepositField.ORIGINATING_COMPANY_ID);
    }
    /**
     * Parses payment method values like 'ACH' and 'wire' to a
     * CashReceivedPaymentMethod enum for a specific row index.
     * @returns the parsed enum, or null if no match was found
     */
    getPaymentMethod(rowIndex) {
        var _a, _b;
        const value = this.getProcessedValue(rowIndex, graphql_types_1.DepositField.PAYMENT_METHOD);
        if (typeof value !== "string") {
            return null;
        }
        const rawToPaymentMethod = [
            { raw: "ach", paymentMethod: graphql_types_1.CashReceivedPaymentMethod.ACH },
            { raw: "wire", paymentMethod: graphql_types_1.CashReceivedPaymentMethod.WIRE },
            { raw: "check", paymentMethod: graphql_types_1.CashReceivedPaymentMethod.CHECK },
        ];
        return ((_b = (_a = rawToPaymentMethod.find(({ raw }) => value.toLowerCase().includes(raw))) === null || _a === void 0 ? void 0 : _a.paymentMethod) !== null && _b !== void 0 ? _b : null);
    }
    getShouldIgnoreForProcessing(rowIndex) {
        return this.getProcessedValue(rowIndex, graphql_types_1.DepositField.SHOULD_IGNORE_FOR_PROCESSING);
    }
    getExternalId(rowIndex) {
        return this.getProcessedValue(rowIndex, graphql_types_1.DepositField.EXTERNAL_ID);
    }
    /**
     * Gets names of columns not eligible to be mapped to provided field given
     * mapped columns of other accessors in config. In some cases columns are
     * still eligible to be mapped even if already mapped depending on the accessor.
     *
     * @param field
     * @returns
     */
    getIneligibleColumns(field) {
        const otherFields = this.getFields().filter((otherField) => otherField !== field);
        return otherFields
            .map((otherField) => {
            const fieldConfig = this.getFieldConfig(otherField);
            if (fieldConfig &&
                ((0, types_1.isBinaryColumnOperationAccessor)(fieldConfig === null || fieldConfig === void 0 ? void 0 : fieldConfig.accessor) ||
                    (0, types_1.isStaticColumnOperationAccessor)(fieldConfig.accessor) ||
                    (0, types_1.isColumnCoalesceAccessor)(fieldConfig.accessor) ||
                    (0, types_1.isColumnConcatenateAccessor)(fieldConfig.accessor) ||
                    (0, types_1.isAdvancedFormulaAccessor)(fieldConfig.accessor))) {
                // Consider columns mapped by concat/coalesce formulas or math ops
                // as still eligible to be mapped by other fields
                return [];
            }
            return this.getMappedColumns(otherField);
        })
            .flat();
    }
    static getFieldType(field) {
        return (0, baseMapper_1.getMapperFieldType)(field);
    }
    static buildColumnKeyField(field, key) {
        const fieldType = BankStatementMapper.getFieldType(field);
        return {
            field,
            fieldType,
            accessor: { type: graphql_types_1.ImportFieldAccessorType.COLUMN_KEY, key },
        };
    }
    static buildColumnCoalesceField(field, primaryKey, secondaryKey) {
        const fieldType = BankStatementMapper.getFieldType(field);
        return {
            field,
            fieldType,
            accessor: {
                type: graphql_types_1.ImportFieldAccessorType.COLUMN_COALESCE,
                primaryKey,
                secondaryKey,
            },
        };
    }
    static buildColumnConcatenateField(field, keyA, keyB, delimiter) {
        const fieldType = BankStatementMapper.getFieldType(field);
        return {
            field,
            fieldType,
            accessor: {
                type: graphql_types_1.ImportFieldAccessorType.COLUMN_CONCATENATE,
                keyA,
                keyB,
                delimiter,
            },
        };
    }
    static buildRelativeWordRangeField(field, key, from, wordLimit, textBasis = graphql_types_1.TextBasis.WORD) {
        const fieldType = BankStatementMapper.getFieldType(field);
        return {
            field,
            fieldType,
            accessor: {
                type: graphql_types_1.ImportFieldAccessorType.RELATIVE_WORD_RANGE,
                key,
                from,
                wordLimit,
                textBasis,
            },
        };
    }
    static buildAdvancedFormulaField(field, formula) {
        const fieldType = BankStatementMapper.getFieldType(field);
        return {
            field,
            fieldType,
            accessor: {
                type: graphql_types_1.ImportFieldAccessorType.ADVANCED_FORMULA,
                formula,
            },
        };
    }
    static buildBinaryColumnOperationField(field, operation, keyA, keyB) {
        return {
            field,
            fieldType: graphql_types_1.ImportFieldType.NUMBER,
            accessor: {
                type: graphql_types_1.ImportFieldAccessorType.BINARY_COLUMN_OPERATION,
                operation,
                keyA,
                keyB,
            },
        };
    }
    static buildStaticColumnOperationField(field, operation, key, scalar) {
        return {
            field,
            fieldType: graphql_types_1.ImportFieldType.NUMBER,
            accessor: {
                type: graphql_types_1.ImportFieldAccessorType.STATIC_COLUMN_OPERATION,
                operation,
                key,
                scalar,
            },
        };
    }
    static buildStaticDateColumnOperationField(field, operation, key, duration) {
        return {
            field,
            fieldType: graphql_types_1.ImportFieldType.DATE,
            accessor: {
                type: graphql_types_1.ImportFieldAccessorType.STATIC_DATE_COLUMN_OPERATION,
                operation,
                key,
                duration,
            },
        };
    }
    static buildManualDateSetField(field, value) {
        return {
            field,
            fieldType: graphql_types_1.ImportFieldType.DATE,
            accessor: { type: graphql_types_1.ImportFieldAccessorType.MANUAL_DATE_SET, value },
        };
    }
}
exports.BankStatementMapper = BankStatementMapper;
BankStatementMapper.fromJSON = (data, fieldMapper) => new BankStatementMapper(new jsonParser_1.JSONParser(data), fieldMapper);
