import { assertDefined } from "../../src/utils";
import { formatCarrierStatementName } from "../../src/formatting";
import { StatementPostedNotificationPayload } from "../../src/generated/graphql";
import { BaseNotification } from "./BaseNotification";

export const StatementPostedNotification = ({
  payload: { statement, outboundStatement },
  createdAt,
}: {
  payload: StatementPostedNotificationPayload;
  createdAt: string;
}) => {
  assertDefined(statement);
  assertDefined(outboundStatement);

  return (
    <BaseNotification
      createdAt={createdAt}
      user={outboundStatement.postedBy}
      href={`/statements?statementId=${statement.id}&outboundStatementId=${outboundStatement.id}`}
    >
      {({ userLabel }) => (
        <>
          <span className="font-medium text-zinc-900">{userLabel}</span>
          {" posted "}
          <span className="font-medium text-green-900">
            {formatCarrierStatementName(statement)}
          </span>
        </>
      )}
    </BaseNotification>
  );
};
