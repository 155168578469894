"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HyperFormulaCustomTranslations = exports.HyperFormulaCustom = void 0;
const hyperformula_1 = require("hyperformula");
const utils_1 = require("../import/utils");
const luxon_1 = require("luxon");
const _ = __importStar(require("lodash"));
class HyperFormulaCustom extends hyperformula_1.FunctionPlugin {
    parseDate(ast, state) {
        return this.runFunction(ast.args, state, this.metadata("PARSE_DATE"), (value) => {
            const parsedValue = (0, utils_1.parseDate)(value.toString(), true);
            if (!parsedValue)
                return "";
            return luxon_1.DateTime.fromJSDate(parsedValue).toFormat("M/d/yy");
        });
    }
    /**
     * Parses numbers using the more lenient DEFAULT mode - by default, we parse sheet
     * values with STRICT mode for HyperFormula to avoid unintentional type coercion.
     * This is useful when we want to override that number parsing behavior.
     *
     * @param ast
     * @param state
     * @returns
     */
    parseNumber(ast, state) {
        return this.runFunction(ast.args, state, this.metadata("PARSE_NUMBER"), (value) => { var _a; return (_a = (0, utils_1.parseNumber)(value.toString(), utils_1.ParseNumberMode.DEFAULT)) !== null && _a !== void 0 ? _a : ""; });
    }
    concatenate(ast, state) {
        return this.runFunction(ast.args, state, this.metadata("CONCATENATE"), (...args) => {
            return args
                .filter((arg) => typeof arg !== "symbol")
                .map((arg) => arg === null || arg === void 0 ? void 0 : arg.toString())
                .filter((value) => !(0, utils_1.isEmptyValue)(value))
                .join(" ");
        });
    }
    formatDate(ast, state) {
        return this.runFunction(ast.args, state, this.metadata("FORMAT_DATE"), (value, format = "M/d/yy") => {
            const parsedDate = (0, utils_1.parseDate)(value);
            if (!parsedDate)
                return "";
            return luxon_1.DateTime.fromJSDate(parsedDate).toFormat(format.toString());
        });
    }
    /**
     * Evaluates whether specified tag is present in TAGS column (case-insensitive).
     *
     * Any caller which uses this formula MUST provide a TAGS column in the sheet
     * with comma-separated tags.
     *
     * @param ast
     * @param state
     * @returns
     */
    hasTag(ast, state) {
        return this.runFunction(ast.args, state, this.metadata("HAS_TAG"), (tag) => {
            const sheetId = state.formulaAddress.sheet;
            const rowIndex = state.formulaAddress.row;
            const colIndex = this.getColumnIndexByName(sheetId, "tags");
            const tags = this.serialization.getCellValue({
                sheet: sheetId,
                col: colIndex,
                row: rowIndex,
            });
            if (typeof tags !== "string")
                return false;
            const tagsArr = tags.split(",").map((tag) => tag.trim().toLowerCase());
            return tagsArr.includes(tag.trim().toLowerCase());
        });
    }
    getColumnIndexByName(sheetId, columnName) {
        // Assume header row is first row
        const [headers] = this.serialization.getSheetSerialized(sheetId);
        return headers.findIndex((header) => (header === null || header === void 0 ? void 0 : header.toString().toLowerCase()) === columnName.toLowerCase());
    }
}
exports.HyperFormulaCustom = HyperFormulaCustom;
HyperFormulaCustom.implementedFunctions = {
    PARSE_DATE: {
        method: "parseDate",
        parameters: [
            {
                argumentType: hyperformula_1.FunctionArgumentType.ANY,
            },
        ],
    },
    PARSE_NUMBER: {
        method: "parseNumber",
        parameters: [
            {
                argumentType: hyperformula_1.FunctionArgumentType.ANY,
            },
        ],
    },
    CONCATENATE: {
        method: "concatenate",
        parameters: _.range(30).map(() => ({
            argumentType: hyperformula_1.FunctionArgumentType.ANY,
            optionalArg: true,
        })),
    },
    FORMAT_DATE: {
        method: "formatDate",
        parameters: [
            {
                argumentType: hyperformula_1.FunctionArgumentType.STRING,
            },
            {
                argumentType: hyperformula_1.FunctionArgumentType.STRING,
                optionalArg: true,
            },
        ],
    },
    HAS_TAG: {
        method: "hasTag",
        parameters: [
            {
                argumentType: hyperformula_1.FunctionArgumentType.STRING,
            },
        ],
    },
};
exports.HyperFormulaCustomTranslations = {
    enGB: {
        PARSE_DATE: "PARSE_DATE",
        PARSE_NUMBER: "PARSE_NUMBER",
        FORMAT_DATE: "FORMAT_DATE",
        HAS_TAG: "HAS_TAG",
    },
};
