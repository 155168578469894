import { assertDefined, assertUnreachableValue } from "../../src/utils";
import {
  BillingMode,
  StatementEntryStatus,
  ThreadEmailResponseReceivedNotificationPayload,
} from "../../src/generated/graphql";
import { BaseNotification } from "./BaseNotification";
import { useMemo } from "react";
import Avvvatars from "avvvatars-react";
import { AtSymbolIcon } from "@heroicons/react/20/solid";
import { getPayablesUrl } from "../../src/urlUtils";

export const ThreadEmailResponseReceivedNotification = ({
  payload,
  createdAt,
}: {
  payload: ThreadEmailResponseReceivedNotificationPayload;
  createdAt: string;
}) => {
  const href = useMemo(() => {
    const entry = payload.threadStory.thread.statementEntries.at(0);

    assertDefined(entry);

    const policyNumberUri = entry.policyNumber
      ? `&policyNumber=${encodeURIComponent(entry.policyNumber)}`
      : "";
    const clientUri = entry.client
      ? `&client=${encodeURIComponent(entry.client)}`
      : "";

    switch (entry.billingMode) {
      case BillingMode.DIRECT_BILL:
      case null:
      case undefined: {
        if (entry.status === StatementEntryStatus.TRIAGE) {
          return `/triage?statementId=${entry.carrierStatementId}&entryId=${entry.id}`;
        } else {
          return `/statements?statementId=${entry.carrierStatementId}${policyNumberUri}${clientUri}`;
        }
      }
      case BillingMode.AGENCY_BILL: {
        const baseUrl = getPayablesUrl({
          type: "statement",
          statementId: entry.carrierStatementId,
        });

        return `${baseUrl}?hasComments=true${policyNumberUri}${clientUri}`;
      }
      default: {
        return assertUnreachableValue(entry.billingMode);
      }
    }
  }, [payload.threadStory.thread.statementEntries]);

  return (
    <BaseNotification
      createdAt={createdAt}
      href={href}
      user={{
        id:
          payload.inboundEmail.originalFromName ||
          payload.inboundEmail.originalFrom,
        fullName:
          payload.inboundEmail.originalFromName ||
          payload.inboundEmail.originalFrom,
      }}
      overrideAvatar={
        <div className="relative">
          <div className="h-8 w-8 min-h-[32px] min-w-[32px] rounded-full">
            <Avvvatars
              size={24}
              style="shape"
              value={payload.inboundEmail.originalFrom.toLowerCase()}
            />
          </div>
          <span className="bg-green-600 size-3 flex items-center justify-center rounded-full absolute top-[14px] left-[14px]">
            <AtSymbolIcon className="size-2 text-white" />
          </span>
        </div>
      }
    >
      {({ userLabel }) => (
        <div className="line-clamp-3">
          <span className="font-medium text-zinc-900">{userLabel}</span>{" "}
          replied:{" "}
          <span className="font-medium text-zinc-900">
            {payload.inboundEmail.strippedContent}
          </span>
        </div>
      )}
    </BaseNotification>
  );
};
