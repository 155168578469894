import { formatRelativeTimestamp } from "../../src/formatting";
import Link from "next/link";
import { useUserAvatarUrls } from "../../hooks/useUserAvatarUrls";
import OwlAvatar from "../../components/icons/OwlAvatar";

export const BaseNotification = ({
  href,
  user,
  createdAt,
  overrideAvatar,
  children,
}: {
  user: { id: string; fullName: string } | null | undefined;
  href: string;
  createdAt: string;
  overrideAvatar?: React.ReactNode;
  children: ({ userLabel }: { userLabel: string }) => React.ReactNode;
}) => {
  const { avatarUrlForUserId } = useUserAvatarUrls();

  return (
    <Link className="block" href={href}>
      <div className="py-3 px-4 flex space-x-3 hover:bg-zinc-50 transition-colors duration-150">
        <div className="shrink-0">
          {overrideAvatar ? (
            overrideAvatar
          ) : user ? (
            <img
              src={avatarUrlForUserId(user.id)}
              className="rounded-full h-6 w-6"
            />
          ) : (
            <OwlAvatar className="rounded-full bg-white h-6 w-6" />
          )}
        </div>
        <div className="space-y-1 text-sm text-zinc-700 min-w-0">
          {children({ userLabel: user ? user.fullName : "Owl" })}
          <div className="text-xs leading-none font-normal text-zinc-500">
            {formatRelativeTimestamp(createdAt)}
          </div>
        </div>
      </div>
    </Link>
  );
};
