import { assertUnreachableValue } from "@comulate/file-utils";
import { UserRole } from "@comulate/graphql-types";
import { useMemo } from "react";
import useFeatureFlag, {
  FeatureFlag,
} from "../../../feature-flags/useFeatureFlag";
import { useLoggedInUser } from "../../LoggedInUserProvider";
import { GLOBAL_NAV } from "../navigationConstants";
import { isNullOrUndefined } from "../../../src/formatting";

const useNavItems = () => {
  const { user, organization } = useLoggedInUser();

  // Whether OWL is enabled for organization
  const enableOwl = useFeatureFlag<boolean>(FeatureFlag.OWL) ?? false;

  const agencyBillAccountsPayableFeature =
    useFeatureFlag<boolean>(FeatureFlag.AGENCY_BILL_ACCOUNT_PAYABLES) ?? false;

  const agencyBillDepositsFeature = useFeatureFlag<boolean>(
    FeatureFlag.AGENCY_BILL_DEPOSITS
  );
  const agencyBillInvoicingFeature =
    useFeatureFlag<boolean>(FeatureFlag.AGENCY_BILL_INVOICING) ?? false;

  const navItems = useMemo(() => {
    const {
      DASHBOARD,
      DEPOSITS,
      OWL,
      PAYABLES,
      BILLING,
      STATEMENTS,
      TRIAGE,
      LEDGER,
      INSIGHTS,
      EARNINGS,
      MORE,
    } = GLOBAL_NAV;

    const role = user?.role;

    if (isNullOrUndefined(role)) return [];

    switch (role) {
      case UserRole.STANDARD:
      case UserRole.ADMIN: {
        return [
          [DASHBOARD],
          [...(agencyBillDepositsFeature ? [DEPOSITS] : [])],
          [...(enableOwl ? [OWL] : []), STATEMENTS, TRIAGE, LEDGER],
          [
            ...(agencyBillInvoicingFeature ? [BILLING] : []),
            ...(agencyBillAccountsPayableFeature ? [PAYABLES] : []),
          ],
          [...(organization?.flags.disableRevenueInsights ? [] : [INSIGHTS])],
          [MORE],
        ].filter((section) => section.length);
      }
      case UserRole.PAYEE: {
        return [
          [EARNINGS],
          [STATEMENTS],
          [...(organization?.flags.disableRevenueInsights ? [] : [INSIGHTS])],
        ].filter((section) => section.length);
      }
      case UserRole.REVENUE_ANALYST: {
        return [[DASHBOARD], [INSIGHTS], [MORE]];
      }
      case UserRole.TRIAGER:
      case UserRole.TRIAGE_VIEWER: {
        return [[TRIAGE]];
      }
      default:
        return assertUnreachableValue(role);
    }
  }, [
    user?.role,
    agencyBillDepositsFeature,
    enableOwl,
    agencyBillAccountsPayableFeature,
    agencyBillInvoicingFeature,
    organization?.flags.disableRevenueInsights,
  ]);

  return navItems;
};

export default useNavItems;
