"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.isFixedEliminationBlockMatchPolicy = exports.isFixedBlocksBoundaryConfig = exports.isCellMatchBoundaryConfig = exports.isPositionBoundaryConfig = exports.isRelativeFieldBoundaryConfig = exports.getContainingRows = exports.getContainingRow = exports.getBlockType = exports.getAlignedPosition = exports.getStartPosition = exports.getFieldBounds = exports.sortByPosition = exports.coalesceFlag = exports.getCharacterPattern = exports.containsEndOfRow = exports.isValidMatch = exports.isValidMatchedBlockText = exports.getProcessedDateText = exports.getParseOptions = exports.blockContainsValidFixedContent = exports.getValidBlockTypes = exports.getBaseField = exports.isShadowField = exports.getFieldType = exports.getFieldFromFieldName = exports.groupPositionsByLine = void 0;
const luxon_1 = require("luxon");
const graphql_types_1 = require("@comulate/graphql-types");
const chrono_1 = require("../../import/chrono");
const utils_1 = require("../../import/utils");
const constants_1 = require("../constants");
const constants_2 = require("./constants");
const _ = __importStar(require("lodash"));
const utils_2 = require("../../common/utils");
/**
 * Groups provided positions by line, where a line is defined as a group of
 * positions that are within a certain threshold of each other
 *
 * @param blockPositions - arary of position tuples, where the first element is
 *        some identifier for the position and the second element is the y position
 * @returns
 */
const groupPositionsByLine = (blockPositions, yScale) => {
    if (!blockPositions.length)
        return [];
    // Sort by descending y position
    blockPositions.sort((a, b) => (a[1] > b[1] ? 1 : -1));
    // Initialize first group with first row
    const groupedPositions = [
        [blockPositions[0][1], [blockPositions[0][0]]],
    ];
    for (let i = 1; i < blockPositions.length; i++) {
        const label = blockPositions[i][0];
        const positionY = blockPositions[i][1];
        const prevLine = groupedPositions[groupedPositions.length - 1];
        const offset = yScale ? Math.pow(yScale, 1.1) : constants_2.LINE_OFFSET_ERROR_THRESHOLD;
        if (positionY - prevLine[0] < offset) {
            prevLine[1].push(label);
        }
        else {
            groupedPositions.push([positionY, [label]]);
        }
    }
    return groupedPositions;
};
exports.groupPositionsByLine = groupPositionsByLine;
const getFieldFromFieldName = (fieldName) => {
    const entry = Object.entries(constants_1.ADEM_FIELDS_METADATA).find(([, { header }]) => header === fieldName);
    return entry ? entry[0] : graphql_types_1.AdemField.CUSTOM;
};
exports.getFieldFromFieldName = getFieldFromFieldName;
/**
 * Provides additional type metadata for  determining column boundaries
 * via switch in field type + eventually validating block type is correct.
 *
 * Eventually we can have more advanced classifications beyond simply string/date/number.
 *
 * @param field
 * @returns
 */
const getFieldType = (field) => constants_1.ADEM_FIELDS_METADATA[field].type;
exports.getFieldType = getFieldType;
const isShadowField = (header) => header.includes(constants_2.SHADOW_FIELD_TOKEN);
exports.isShadowField = isShadowField;
const getBaseField = (header) => header.replace(constants_2.SHADOW_FIELD_TOKEN, "");
exports.getBaseField = getBaseField;
const getValidBlockTypes = (blocks, { parseNumberMode, parseDateStrict } = {}) => {
    const blockTypes = [];
    const blockText = blocks.map((block) => block.text).join("");
    if ((0, utils_1.containsValidNumber)(blockText, false, parseNumberMode === utils_1.ParseNumberMode.STRICT) ||
        blockText === "-") {
        blockTypes.push(graphql_types_1.AdemFieldType.NUMBER);
    }
    if (blockTypes.length === 0 &&
        (0, utils_1.containsValidDate)(blockText, false, parseDateStrict)) {
        blockTypes.push(graphql_types_1.AdemFieldType.DATE);
    }
    if (blockTypes.length === 0 || blockText === "-") {
        blockTypes.push(graphql_types_1.AdemFieldType.STRING);
    }
    return blockTypes;
};
exports.getValidBlockTypes = getValidBlockTypes;
const blockContainsValidFixedContent = (block, ignoreFillerBlocks) => !ignoreFillerBlocks || /[a-zA-Z0-9$]/.test(block.text);
exports.blockContainsValidFixedContent = blockContainsValidFixedContent;
/**
 * Checks whether know expected type of block (as provided by if labeled field
 * in template), and uses this value if present to determine whether strict number
 * checks should be enabled (we should only allow non-strict checks for when we
 * expect value to be numeric)
 *
 * @param template
 * @param block
 * @returns
 */
const getParseOptions = (fieldType) => ({
    parseNumberMode: graphql_types_1.AdemFieldType.NUMBER
        ? utils_1.ParseNumberMode.DEFAULT
        : utils_1.ParseNumberMode.STRICT,
    parseDateStrict: fieldType !== graphql_types_1.AdemFieldType.DATE,
});
exports.getParseOptions = getParseOptions;
const convertMmDdToDate = (text) => {
    var _a;
    const strippedText = text.replaceAll(/\s+/g, "");
    const delimiterChar = ((_a = strippedText.match(/\D/)) === null || _a === void 0 ? void 0 : _a[0]) || "";
    const parts = delimiterChar
        ? strippedText.split(delimiterChar)
        : [strippedText.slice(0, -2), strippedText.slice(-2)];
    const monthIndex = (0, utils_1.parseNumber)(parts[0], utils_1.ParseNumberMode.DEFAULT);
    if (!monthIndex)
        return text;
    const year = (0, chrono_1.getYearFromMonthIndex)(monthIndex - 1, luxon_1.DateTime.now());
    return `${strippedText}${delimiterChar}${year.toString().slice(-2)}`;
};
const convertYyMmToDate = (text) => {
    const strippedText = text.replaceAll(/\s+/g, "");
    const chars = strippedText.split("");
    const delimiterChar = chars.length === 5 ? chars[2] : "";
    const year = new Date().getFullYear().toString().slice(0, 2);
    return `${year}${strippedText}${delimiterChar}01`;
};
const getProcessedDateText = (text, flags) => {
    let processedText = flags.enableMmDdDateParsing
        ? convertMmDdToDate(text)
        : flags.enableYyMmDateParsing
            ? convertYyMmToDate(text)
            : text;
    // Chrono date parser expects all MM-YY formatted dates to include leading
    // zero, so we append here for any 3-digit numbers that could be dates but lack
    // leading zero
    if (/^\d{3}$/.test(processedText)) {
        processedText = `0${processedText}`;
    }
    return processedText;
};
exports.getProcessedDateText = getProcessedDateText;
const isValidMatchedBlockText = (fieldType, matchedBlockText, flags = {}) => {
    const { parseNumberMode } = (0, exports.getParseOptions)(fieldType);
    switch (fieldType) {
        case graphql_types_1.AdemFieldType.NUMBER: {
            return (0, utils_1.containsValidNumber)(matchedBlockText, false, parseNumberMode === utils_1.ParseNumberMode.STRICT);
        }
        case graphql_types_1.AdemFieldType.DATE: {
            return (0, utils_1.containsValidDate)((0, exports.getProcessedDateText)(matchedBlockText, flags), false, fieldType !== graphql_types_1.AdemFieldType.DATE);
        }
        case graphql_types_1.AdemFieldType.STRING:
            return (0, utils_1.containsValidString)(matchedBlockText, false);
    }
};
exports.isValidMatchedBlockText = isValidMatchedBlockText;
/**
 * Performs extra validation that parsed match (as determined to be within
 * boundaries of provided field config) is actually valid. This can be manually
 * specified via the filter function, or automatically determined based on the
 * assumed field types.
 *
 * @param matchedBlocks
 * @param field
 * @param filter
 * @returns
 */
const isValidMatch = (row, matchedBlocks, field, fieldType, flags, filter) => {
    if (!matchedBlocks.length)
        return false;
    if (filter) {
        return filter(row, matchedBlocks);
    }
    const blockText = matchedBlocks.map((block) => block.text).join(" ");
    const isValid = (0, exports.isValidMatchedBlockText)(fieldType, blockText, flags);
    // For numeric fields, require that there are no overlapping blocks in y-plane
    // (they aren't "stacked" on top of each other) and we don't suspect extra misc blocks
    // are being picked up, as determined by matching >1 block containing >2 digits
    if (isValid &&
        fieldType === graphql_types_1.AdemFieldType.NUMBER &&
        matchedBlocks.length > 1) {
        if (matchedBlocks.some((block) => {
            const otherBlocks = matchedBlocks.filter(({ id }) => id !== block.id);
            return otherBlocks.some((otherBlock) => otherBlock.boundingBox.y >
                block.boundingBox.y + block.boundingBox.height);
        })) {
            // Ensure no other block is fully "underneath" matched block
            return false;
        }
    }
    if (isValid && constants_2.DECIMAL_SPLICE_ENABLED_FIELDS.includes(field)) {
        // Ensure we haven't accidentally picked up other numeric field unlikely to be
        // premium/commission (e.g. percentages)
        return !/%/.test(blockText);
    }
    return isValid;
};
exports.isValidMatch = isValidMatch;
/**
 * Checks whether all row matches are consecutively at end of row
 *
 * @param boundaryConfigs
 * @param rowMatch
 * @returns
 */
const containsEndOfRow = (fieldTypes, baseRequiredHeaders, boundaryHeaders, matchedHeaders) => {
    if (!fieldTypes.some((fieldType) => fieldType === graphql_types_1.AdemFieldType.NUMBER)) {
        return false;
    }
    const optionalHeaders = boundaryHeaders.filter((header) => constants_1.ADEM_STANDARD_HEADERS.includes(header) &&
        !baseRequiredHeaders.includes(header));
    const inds = matchedHeaders.map((matchedHeader) => boundaryHeaders.length -
        boundaryHeaders.findIndex((boundaryHeader) => matchedHeader === boundaryHeader));
    for (const optionalHeader of optionalHeaders) {
        if (!matchedHeaders.includes(optionalHeader)) {
            // Pretend as though field was matched if optional field missing
            inds.push(boundaryHeaders.length - boundaryHeaders.indexOf(optionalHeader));
        }
    }
    // Consider match valid if all fields are consecutively at end of row
    return _.sum(inds) === (inds.length / 2) * (inds.length + 1);
};
exports.containsEndOfRow = containsEndOfRow;
/**
 * Gets the character pattern of the text of input blocks. Characters
 * are bucketed into digits + lowercase letters + uppercase letters. All
 * characters that don't fall into these buckets as persisted as is.
 *
 * Exception is "keywords" (words preceding a colon), which are also persisted.
 *
 * For instance, the following examples would have the following patterns:
 *   - 100023 -> dddddd
 *   - A1002B -> uddddu
 *   - A100-1 -> uddd-d
 *   - A100 B -> uddd u
 *   - Group: A1000 -> Group: udddd
 */
const getCharacterPattern = (blocks) => {
    const blockText = blocks
        .filter(utils_2.isNotNullAndNotUndefined)
        .map((block) => block.text)
        .join(" ");
    // Match up to 2 words preceding colon
    const keywordMatches = [...blockText.matchAll(/([a-zA-Z#]*\s?){1,2}:/g)];
    // For words preceding a colon, return exact match
    const charTypes = blockText.split("").map((char, charInd) => {
        if (keywordMatches.some((match) => (0, utils_2.isNotNullAndNotUndefined)(match.index) &&
            charInd >= match.index &&
            charInd < match.index + match[0].length)) {
            return char;
        }
        if (/\d/.test(char))
            return "d";
        if (/[A-Z]/.test(char))
            return "u";
        if (/[a-z]/.test(char))
            return "l";
        return char;
    });
    return charTypes.join("");
};
exports.getCharacterPattern = getCharacterPattern;
const coalesceFlag = (flagA, flagB) => (0, utils_2.isNotNullAndNotUndefined)(flagA) ? flagA : flagB;
exports.coalesceFlag = coalesceFlag;
const sortByPosition = (a, b) => {
    if ((0, exports.isPositionBoundaryConfig)(a.startBound) &&
        (0, exports.isPositionBoundaryConfig)(b.startBound)) {
        return a.startBound.positionX > b.startBound.positionX ? 1 : -1;
    }
    throw new Error("Start boundary config not expected to have type relative-field");
};
exports.sortByPosition = sortByPosition;
/**
 * Returns average block bounds of provided template for downstream
 * use in determining column boundaries, alignment, etc.
 *
 * Generates additional bounds for matching if template is sparse to
 * prevent accidental false positives. These additional bounds will be used
 * to generate "shadow fields", which are automatically creating from unlabeled
 * fields neighboring labeled fields in the cases of sparse templates.
 *
 * A sparse template is any template with <=3 labeled fields (or whatever
 * SHADOW_FIELD_THRESHOLD is set to)
 *
 * @param template
 * @param rows
 * @returns
 */
const getFieldBounds = (template, rows, flags) => {
    const templateBlockIds = Object.values(template).flat();
    const bounds = Object.entries(template).flatMap(([header, blockIds]) => {
        const row = (0, exports.getContainingRow)(rows, blockIds);
        const blockBounds = blockIds
            .map((blockId) => { var _a; return (_a = row.find((lRowCell) => lRowCell.id === blockId)) === null || _a === void 0 ? void 0 : _a.boundingBox; })
            .filter(utils_2.isNotNullAndNotUndefined);
        const field = (0, exports.getFieldFromFieldName)(header);
        const xMin = Math.min(...blockBounds.map((geo) => geo.x));
        const xMax = Math.max(...blockBounds.map((geo) => geo.x + geo.width));
        const xMid = (xMin + xMax) / 2;
        const lastBlockInd = row.findIndex((cell) => cell.id === blockIds[blockIds.length - 1]);
        if (!(flags === null || flags === void 0 ? void 0 : flags.disableShadowFields) &&
            lastBlockInd < row.length - 1 &&
            Object.entries(template).length <= constants_2.SHADOW_FIELD_THRESHOLD &&
            !templateBlockIds.includes(row[lastBlockInd + 1].id)) {
            const boundingBox = row[lastBlockInd + 1].boundingBox;
            // Require additional matches for sparse templates
            return [
                {
                    field,
                    fieldName: header,
                    xMin,
                    xMid,
                    xMax,
                },
                {
                    field: graphql_types_1.AdemField.CUSTOM,
                    fieldName: `${header}${constants_2.SHADOW_FIELD_TOKEN}`,
                    xMin: boundingBox.x,
                    xMid: boundingBox.x + boundingBox.width / 2,
                    xMax: boundingBox.x + boundingBox.width,
                    blockType: (0, exports.getBlockType)(row[lastBlockInd + 1]),
                },
            ];
        }
        return [{ field, fieldName: header, xMin, xMid, xMax }];
    });
    return bounds
        .map(({ fieldName, blockType, xMin, xMid, xMax }) => ({
        field: (0, exports.getFieldFromFieldName)(fieldName),
        fieldName,
        xMin,
        xMid,
        xMax,
        blockType,
    }))
        .filter(({ xMin, xMid, xMax }) => isDefinedNumber(xMin) && isDefinedNumber(xMid) && isDefinedNumber(xMax))
        .sort((a, b) => a.xMin - b.xMin);
};
exports.getFieldBounds = getFieldBounds;
const getStartPosition = (startBound) => {
    if ((0, exports.isPositionBoundaryConfig)(startBound)) {
        return startBound.positionX;
    }
    throw new Error("Start boundary config not expected to have type relative-field");
};
exports.getStartPosition = getStartPosition;
const getAlignedPosition = (alignment, block) => {
    switch (alignment) {
        case graphql_types_1.AdemFieldAlignment.LEFT:
            return block.boundingBox.x;
        case graphql_types_1.AdemFieldAlignment.RIGHT:
            return block.boundingBox.x + block.boundingBox.width;
        case graphql_types_1.AdemFieldAlignment.CENTER:
            return block.boundingBox.x + block.boundingBox.width / 2;
    }
};
exports.getAlignedPosition = getAlignedPosition;
const getBlockType = (block, parseOptions) => (0, exports.getValidBlockTypes)([block], parseOptions)[0];
exports.getBlockType = getBlockType;
const getContainingRow = (rows, blockIds) => _.maxBy(rows, (row) => row.filter((rowCell) => blockIds.includes(rowCell.id)).length) || [];
exports.getContainingRow = getContainingRow;
const getContainingRows = (rows, blockIds) => rows.filter((row) => row.some((cell) => blockIds.includes(cell.id)));
exports.getContainingRows = getContainingRows;
const isDefinedNumber = (value) => (0, utils_2.isNotNullAndNotUndefined)(value) &&
    !isNaN(value) &&
    value !== Infinity &&
    value !== -Infinity;
const isRelativeFieldBoundaryConfig = (config) => config.type === "RELATIVE_FIELD";
exports.isRelativeFieldBoundaryConfig = isRelativeFieldBoundaryConfig;
const isPositionBoundaryConfig = (config) => config.type === "POSITION";
exports.isPositionBoundaryConfig = isPositionBoundaryConfig;
const isCellMatchBoundaryConfig = (config) => config.type === "CELL_TYPE";
exports.isCellMatchBoundaryConfig = isCellMatchBoundaryConfig;
const isFixedBlocksBoundaryConfig = (config) => config.type === "FIXED_BLOCKS";
exports.isFixedBlocksBoundaryConfig = isFixedBlocksBoundaryConfig;
const isFixedEliminationBlockMatchPolicy = (policy) => policy.type === "FIXED_ELIMINATION";
exports.isFixedEliminationBlockMatchPolicy = isFixedEliminationBlockMatchPolicy;
